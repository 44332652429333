import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'
import { unref, useRoute } from '@nuxtjs/composition-api'

export function getLinkRewrites($config) {
  return [
    [
      // The first host is the target for the rewrites in the list, it is environment dependent
      $config.wwwSiteUrl.match(/^(?<protocol>[^:]+:)?(\/\/(?<host>[^/]+))?(?<rest>.*$)/i).groups.host,
      'dierenbescherming.nl',
      'www.dierenbescherming.nl',
      'p.dierenbescherming.nl',
      'a.dierenbescherming.nl',
      'test',
    ],
    [
      // The first host is the target for the rewrites in the list, it is environment dependent
      $config.izbSiteUrl.match(/^(?<protocol>[^:]+:)?(\/\/(?<host>[^/]+))?(?<rest>.*$)/i).groups.host,
      'ikzoekbaas.dierenbescherming.nl',
      'p-ikzoekbaas.dierenbescherming.nl',
      'a-ikzoekbaas.dierenbescherming.nl',
      'ikzoekbaas.test',
    ],
    [
      // The first host is the target for the rewrites in the list, it is environment dependent
      $config.mdizSiteUrl.match(/^(?<protocol>[^:]+:)?(\/\/(?<host>[^/]+))?(?<rest>.*$)/i).groups.host,
      'mijndieriszoek.dierenbescherming.nl',
      'p-mijndieriszoek.dierenbescherming.nl',
      'a-mijndieriszoek.dierenbescherming.nl',
      'mijndieriszoek.test',
    ],
    [
      // The first host is the target for the rewrites in the list, it is environment dependent
      $config.lidSiteUrl.match(/^(?<protocol>[^:]+:)?(\/\/(?<host>[^/]+))?(?<rest>.*$)/i).groups.host,
      'inspectiedienst.nl',
      'www.inspectiedienst.nl',
      'p.inspectiedienst.nl',
      'a.inspectiedienst.nl',
      'inspectiedienst.test',
    ],
  ]
}

/**
 * Returns the domain and site
 *
 * `domain` can be
 * - 'default'
 * - 'ikZoekBaas'
 * - 'mijnDierIsZoek'
 *
 * `site` can be
 * - Either the siteSlug (i.e. 'knaagspoor') or
 * - the base site (i.e. 'ikZoekBaas' for all routes in ikzoekbaas subdomain) or
 * - the base site (i.e. 'mijnDierIsZoek' for all routes in mijndieriszoek subdomain) or
 * - the corporate site (i.e. 'default')
 *
 * @param route
 * @return {{ domain:string, site:string, siteGroup:string }}
 */
export function getSiteConfig(route) {
  // It is very strange but the meta data (where the site, but not the siteSlug, see /config/router/index.js) is stored,
  // is sometimes and object and other times an array with objects
  const domain = unref(route).meta?.domain || unref(route).meta?.[0]?.domain || 'default'
  const departmentTypeSlug = unref(route).params.departmentTypeSlug
  const siteSlug = craftSiteGroupMap[unref(route).params.siteSlug] ? unref(route).params.siteSlug : undefined
  const site = camelCase((craftSiteGroupMap[siteSlug] && siteSlug) || domain)
  const siteGroup = craftSiteGroupMap[kebabCase(site)]
  return { departmentTypeSlug, domain, site, siteGroup, siteSlug }
}

/**
 * Returns the domain and site
 *
 * `domain` can be
 * - 'default'
 * - 'ikZoekBaas'
 * - 'mijnDierIsZoek'
 *
 * `site` can be
 * - Either the siteSlug (i.e. 'knaagspoor') or
 * - the base site (i.e. 'ikZoekBaas' for all routes in ikzoekbaas subdomain) or
 * - the base site (i.e. 'mijnDierIsZoek' for all routes in mijndieriszoek subdomain) or
 * - the corporate site (i.e. 'default')
 *
 * @returns { domain:string, site:string, siteGroup:string }
 */
export function setupSiteConfig() {
  return getSiteConfig(useRoute())
}

/**
 * @param {string} slug
 * @returns {string}
 */
export function getLandingPageUri(slug) {
  const groupUid = craftSiteGroupMap[slug] || null
  const group = craftGroups[groupUid] || null
  if (group === null) {
    return ''
  }
  return `/${group}/${slug}`
}

const craftGroups = {
  'f206e6cb-be52-40f1-9d85-bd906a2857f9': 'extern',
  'a6e52d28-da7f-45ce-906d-be41c0359ccb': 'dierenambulance',
  'a20c99c0-69a6-4def-871a-9b3cf300c006': 'dierenasiel',
  '91010559-3838-433f-ad0a-9e736947a948': 'hondenschool',
  '540ad60f-a43f-4bad-8499-00525ae05a80': 'dierenasiel',
  '383b180f-e8cc-4f3b-8281-ad6cbd4713dd': 'wildopvang',
}

const craftSiteGroupMap = {
  // External Departments
  'dierenasiel-de-dierenstee': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierenasiel-gorinchem': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierenasiel-tiel': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierenasiel-utrecht': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierencentrum-achterhoek': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierencentrum-de-ark': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierenhospitaal-en-dierenambulance-den-haag-eo': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierenopvang-maasland': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierenopvang-schagen-hollands-kroon': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierentehuis-leerdam': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierentehuis-s-hertogenbosch': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierentehuis-t-schuthok': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'dierentehuis-ter-marse': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'instantie-voor-dierenwelzijn-utrecht': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'preventieve-dierenhulp': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'streekdierentehuis-louter-bloemen': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'zwerfkatten-tilburg': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'zwerfkattenwerkgroep-dongen': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'zwerfkattenwerkgroep-west-en-midden-brabant': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  'zwols-dierenasiel': 'f206e6cb-be52-40f1-9d85-bd906a2857f9',
  // Dierenbescherming
  'ik-zoek-baas': 'ddcf22b1-df04-4c71-88cb-3f4d54315708',
  'mijn-dier-is-zoek': 'ddcf22b1-df04-4c71-88cb-3f4d54315708',
  default: 'ddcf22b1-df04-4c71-88cb-3f4d54315708',
  // Dierenambulance
  'brabant-zuid-oost': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'de-heuvelrug': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'de-meren': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'de-wouden': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'midden-nederland': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'noord-en-midden-drenthe': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'noord-holland-noord': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'noord-holland-zuid': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'oost-groningen': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'zuid-holland-zuid': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  'zuidoost-drenthe': 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  almelo: 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  enschede: 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  limburg: 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  maasland: 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  overgelder: 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  twente: 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  zeeland: 'a6e52d28-da7f-45ce-906d-be41c0359ccb',
  // Dierenasiel
  'de-kuipershoek': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'de-swinge': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenbeschermingscentrum-amersfoort': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenbeschermingscentrum-limburg': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenbeschermingscentrum-noord-nederland': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenopvang-haarlemmermeer': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenopvangcentrum-enschede': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenopvangcentrum-gouda': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenopvangcentrum-rotterdam': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenopvangcentrum-spijkenisse': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenopvangcentrum-t-noordbroek': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierenopvangcentrum-vlaardingen': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierentehuis-alkmaar': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierentehuis-de-hof-van-ede': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'dierentehuis-kennemerland': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'kerbert-dierentehuis': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  'stg-hulp-herplaatsing-huisdieren': 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  winschoten: 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  zuidwolde: 'a20c99c0-69a6-4def-871a-9b3cf300c006',
  // Hondenschool
  'gehoorzame-huishond-aalsmeer': '91010559-3838-433f-ad0a-9e736947a948',
  'gehoorzame-huishond-breda': '91010559-3838-433f-ad0a-9e736947a948',
  'gehoorzame-huishond-bunschoten': '91010559-3838-433f-ad0a-9e736947a948',
  'gehoorzame-huishond-epe': '91010559-3838-433f-ad0a-9e736947a948',
  'gehoorzame-huishond-limburg': '91010559-3838-433f-ad0a-9e736947a948',
  'gehoorzame-huishond-nijmegen': '91010559-3838-433f-ad0a-9e736947a948',
  // Knaagdierencentra
  'het-knaaghof': '540ad60f-a43f-4bad-8499-00525ae05a80',
  knaagdierencentrum: '540ad60f-a43f-4bad-8499-00525ae05a80',
  knaagspoor: '540ad60f-a43f-4bad-8499-00525ae05a80',
  // Wildopvang
  'egelopvang-papendrecht': '383b180f-e8cc-4f3b-8281-ad6cbd4713dd',
  krommenie: '383b180f-e8cc-4f3b-8281-ad6cbd4713dd',
}

/**
 * Returns a breadcrumb array based on the structure returned by `pathQuery` from `queries.js`
 */
export function getBreadcrumbs(route, page, prefixes = []) {
  return [
    // The 'home' is always included, taking into account the current subsite
    { label: 'Home', url: getHomeUrl(route)?.url },
    // Optional prefixes can be included, i.e. back to an overview page
    ...prefixes,
    // Optional paths configured in CraftCMS
    page.path?.parent?.parent?.parent?.parent,
    page.path?.parent?.parent?.parent,
    page.path?.parent?.parent,
    page.path?.parent,
    page.path,
    // The page itself
    { label: page.title, url: page.url },
  ].filter((crumb) => crumb?.label && crumb?.url)
}

/**
 * Check if we are on a "dierenasiel" page or not
 * Should match: (all subsites)
 */
export function getHomeUrl({ fullPath, meta }, suffix) {
  const isIkZoekBaas = meta?.site === 'ikZoekBaas'
  const isMijnDierIsZoek = meta?.site === 'mijnDierIsZoek'
  const isInspectiedienst = meta?.site === 'inspectiedienst'

  if (isIkZoekBaas || isMijnDierIsZoek || isInspectiedienst) {
    return {
      // Note that suffix may start with a '/', we will ensure that we never result in '//foo'
      url: suffix ? (suffix.substring(0, 1) === '/' ? suffix : `/${suffix}`) : '/',
      simpleMenu: true,
      ikZoekBaas: isIkZoekBaas,
    }
  }

  if (fullPath) {
    const match = fullPath.match(/^\/(?<siteType>dierenasiel|dierenambulance|extern|hondenschool|wildopvang)\/(?<siteSlug>[^/]+)/)
    if (match) {
      const { siteType, siteSlug } = match.groups
      return {
        // Note that suffix may start with a '/', we will ensure that we never result in '/dierenasiel/knaagspoor//foo'
        url: `/${siteType}/${siteSlug}${suffix && suffix !== '/' ? (suffix.substring(0, 1) === '/' ? suffix : `/${suffix}`) : ''}`,
        simpleMenu: true,
        ikZoekBaas: false,
      }
    }
  }

  return {
    // Note that suffix may start with a '/', we will ensure that we never result in '//foo'
    url: suffix ? (suffix.substring(0, 1) === '/' ? suffix : `/${suffix}`) : '/',
    simpleMenu: false,
    ikZoekBaas: false,
  }
}
