import { render, staticRenderFns } from "./LandingDetail.vue?vue&type=template&id=46bcd5b1"
import script from "./LandingDetail.vue?vue&type=script&lang=js"
export * from "./LandingDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default,AtomButton: require('/home/userapp/components/Atom/Button.vue').default,MoleculeHeaderOverview: require('/home/userapp/components/Molecule/HeaderOverview.vue').default,MoleculeHighlightedAnimals: require('/home/userapp/components/Molecule/HighlightedAnimals.vue').default,MoleculeMatrixBanner: require('/home/userapp/components/Molecule/Matrix/MatrixBanner.vue').default,MoleculeCardHighlight: require('/home/userapp/components/Molecule/CardHighlight.vue').default,MoleculeHighlightedSliderWithText: require('/home/userapp/components/Molecule/HighlightedSliderWithText.vue').default,MoleculeHighlightBanner: require('/home/userapp/components/Molecule/HighlightBanner.vue').default,MoleculeCarouselSlideStory: require('/home/userapp/components/Molecule/Carousel/CarouselSlideStory.vue').default,OrganismLoaderFromUri: require('/home/userapp/components/Organism/Loader/LoaderFromUri.vue').default,MoleculeCarousel: require('/home/userapp/components/Molecule/Carousel/Carousel.vue').default,MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default,MoleculeDonation: require('/home/userapp/components/Molecule/Donation.vue').default,TemplateBase: require('/home/userapp/components/Template/Base.vue').default})
