export const AtomButton = () => import('../../components/Atom/Button.vue' /* webpackChunkName: "components/atom-button" */).then(c => wrapFunctional(c.default || c))
export const AtomIcon = () => import('../../components/Atom/Icon.vue' /* webpackChunkName: "components/atom-icon" */).then(c => wrapFunctional(c.default || c))
export const AtomMobileMenuBackButton = () => import('../../components/Atom/MobileMenuBackButton.vue' /* webpackChunkName: "components/atom-mobile-menu-back-button" */).then(c => wrapFunctional(c.default || c))
export const AtomPreloader = () => import('../../components/Atom/Preloader.vue' /* webpackChunkName: "components/atom-preloader" */).then(c => wrapFunctional(c.default || c))
export const AtomBrandAnbiLogo = () => import('../../components/Atom/Brand/BrandAnbiLogo.vue' /* webpackChunkName: "components/atom-brand-anbi-logo" */).then(c => wrapFunctional(c.default || c))
export const AtomBrandBlijeKipLogo = () => import('../../components/Atom/Brand/BrandBlijeKipLogo.vue' /* webpackChunkName: "components/atom-brand-blije-kip-logo" */).then(c => wrapFunctional(c.default || c))
export const AtomBrandCbfLogo = () => import('../../components/Atom/Brand/BrandCbfLogo.vue' /* webpackChunkName: "components/atom-brand-cbf-logo" */).then(c => wrapFunctional(c.default || c))
export const AtomBrandLogoDesktop = () => import('../../components/Atom/Brand/BrandLogoDesktop.vue' /* webpackChunkName: "components/atom-brand-logo-desktop" */).then(c => wrapFunctional(c.default || c))
export const AtomBrandLogoMobile = () => import('../../components/Atom/Brand/BrandLogoMobile.vue' /* webpackChunkName: "components/atom-brand-logo-mobile" */).then(c => wrapFunctional(c.default || c))
export const AtomBrandNplLogo = () => import('../../components/Atom/Brand/BrandNplLogo.vue' /* webpackChunkName: "components/atom-brand-npl-logo" */).then(c => wrapFunctional(c.default || c))
export const AtomFormulateLabel = () => import('../../components/Atom/Formulate/FormulateLabel.vue' /* webpackChunkName: "components/atom-formulate-label" */).then(c => wrapFunctional(c.default || c))
export const AtomIconArrowDown = () => import('../../components/Atom/Icon/IconArrowDown.vue' /* webpackChunkName: "components/atom-icon-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const AtomIconArrowLeft = () => import('../../components/Atom/Icon/IconArrowLeft.vue' /* webpackChunkName: "components/atom-icon-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const AtomIconArrowRight = () => import('../../components/Atom/Icon/IconArrowRight.vue' /* webpackChunkName: "components/atom-icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const AtomIconArrowUp = () => import('../../components/Atom/Icon/IconArrowUp.vue' /* webpackChunkName: "components/atom-icon-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const AtomIconBirthdayCake = () => import('../../components/Atom/Icon/IconBirthdayCake.vue' /* webpackChunkName: "components/atom-icon-birthday-cake" */).then(c => wrapFunctional(c.default || c))
export const AtomIconCalendar = () => import('../../components/Atom/Icon/IconCalendar.vue' /* webpackChunkName: "components/atom-icon-calendar" */).then(c => wrapFunctional(c.default || c))
export const AtomIconCart = () => import('../../components/Atom/Icon/IconCart.vue' /* webpackChunkName: "components/atom-icon-cart" */).then(c => wrapFunctional(c.default || c))
export const AtomIconChain = () => import('../../components/Atom/Icon/IconChain.vue' /* webpackChunkName: "components/atom-icon-chain" */).then(c => wrapFunctional(c.default || c))
export const AtomIconCheck = () => import('../../components/Atom/Icon/IconCheck.vue' /* webpackChunkName: "components/atom-icon-check" */).then(c => wrapFunctional(c.default || c))
export const AtomIconCheckmark = () => import('../../components/Atom/Icon/IconCheckmark.vue' /* webpackChunkName: "components/atom-icon-checkmark" */).then(c => wrapFunctional(c.default || c))
export const AtomIconChevronDown = () => import('../../components/Atom/Icon/IconChevronDown.vue' /* webpackChunkName: "components/atom-icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const AtomIconChevronLeft = () => import('../../components/Atom/Icon/IconChevronLeft.vue' /* webpackChunkName: "components/atom-icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const AtomIconChevronRight = () => import('../../components/Atom/Icon/IconChevronRight.vue' /* webpackChunkName: "components/atom-icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const AtomIconChevronUp = () => import('../../components/Atom/Icon/IconChevronUp.vue' /* webpackChunkName: "components/atom-icon-chevron-up" */).then(c => wrapFunctional(c.default || c))
export const AtomIconCircledCheck = () => import('../../components/Atom/Icon/IconCircledCheck.vue' /* webpackChunkName: "components/atom-icon-circled-check" */).then(c => wrapFunctional(c.default || c))
export const AtomIconGear = () => import('../../components/Atom/Icon/IconGear.vue' /* webpackChunkName: "components/atom-icon-gear" */).then(c => wrapFunctional(c.default || c))
export const AtomIconHamburger = () => import('../../components/Atom/Icon/IconHamburger.vue' /* webpackChunkName: "components/atom-icon-hamburger" */).then(c => wrapFunctional(c.default || c))
export const AtomIconHeart = () => import('../../components/Atom/Icon/IconHeart.vue' /* webpackChunkName: "components/atom-icon-heart" */).then(c => wrapFunctional(c.default || c))
export const AtomIconHeartFilled = () => import('../../components/Atom/Icon/IconHeartFilled.vue' /* webpackChunkName: "components/atom-icon-heart-filled" */).then(c => wrapFunctional(c.default || c))
export const AtomIconInstagram = () => import('../../components/Atom/Icon/IconInstagram.vue' /* webpackChunkName: "components/atom-icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const AtomIconMail = () => import('../../components/Atom/Icon/IconMail.vue' /* webpackChunkName: "components/atom-icon-mail" */).then(c => wrapFunctional(c.default || c))
export const AtomIconMinus = () => import('../../components/Atom/Icon/IconMinus.vue' /* webpackChunkName: "components/atom-icon-minus" */).then(c => wrapFunctional(c.default || c))
export const AtomIconPaw = () => import('../../components/Atom/Icon/IconPaw.vue' /* webpackChunkName: "components/atom-icon-paw" */).then(c => wrapFunctional(c.default || c))
export const AtomIconPdfFile = () => import('../../components/Atom/Icon/IconPdfFile.vue' /* webpackChunkName: "components/atom-icon-pdf-file" */).then(c => wrapFunctional(c.default || c))
export const AtomIconPencil = () => import('../../components/Atom/Icon/IconPencil.vue' /* webpackChunkName: "components/atom-icon-pencil" */).then(c => wrapFunctional(c.default || c))
export const AtomIconPerson = () => import('../../components/Atom/Icon/IconPerson.vue' /* webpackChunkName: "components/atom-icon-person" */).then(c => wrapFunctional(c.default || c))
export const AtomIconPhone = () => import('../../components/Atom/Icon/IconPhone.vue' /* webpackChunkName: "components/atom-icon-phone" */).then(c => wrapFunctional(c.default || c))
export const AtomIconPiggyBank = () => import('../../components/Atom/Icon/IconPiggyBank.vue' /* webpackChunkName: "components/atom-icon-piggy-bank" */).then(c => wrapFunctional(c.default || c))
export const AtomIconPlay = () => import('../../components/Atom/Icon/IconPlay.vue' /* webpackChunkName: "components/atom-icon-play" */).then(c => wrapFunctional(c.default || c))
export const AtomIconPlus = () => import('../../components/Atom/Icon/IconPlus.vue' /* webpackChunkName: "components/atom-icon-plus" */).then(c => wrapFunctional(c.default || c))
export const AtomIconPowerSwitch = () => import('../../components/Atom/Icon/IconPowerSwitch.vue' /* webpackChunkName: "components/atom-icon-power-switch" */).then(c => wrapFunctional(c.default || c))
export const AtomIconSearch = () => import('../../components/Atom/Icon/IconSearch.vue' /* webpackChunkName: "components/atom-icon-search" */).then(c => wrapFunctional(c.default || c))
export const AtomIconWhatsApp = () => import('../../components/Atom/Icon/IconWhatsApp.vue' /* webpackChunkName: "components/atom-icon-whats-app" */).then(c => wrapFunctional(c.default || c))
export const AtomIconX = () => import('../../components/Atom/Icon/IconX.vue' /* webpackChunkName: "components/atom-icon-x" */).then(c => wrapFunctional(c.default || c))
export const AtomLink = () => import('../../components/Atom/Link/Link.vue' /* webpackChunkName: "components/atom-link" */).then(c => wrapFunctional(c.default || c))
export const AtomLinkWithIcon = () => import('../../components/Atom/Link/LinkWithIcon.vue' /* webpackChunkName: "components/atom-link-with-icon" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCat = () => import('../../components/Atom/IconApp/IconAppCat.vue' /* webpackChunkName: "components/atom-icon-app-cat" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCatJumpingFar = () => import('../../components/Atom/IconApp/IconAppCatJumpingFar.vue' /* webpackChunkName: "components/atom-icon-app-cat-jumping-far" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCatJumpingHigh = () => import('../../components/Atom/IconApp/IconAppCatJumpingHigh.vue' /* webpackChunkName: "components/atom-icon-app-cat-jumping-high" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCatJumpingLeft = () => import('../../components/Atom/IconApp/IconAppCatJumpingLeft.vue' /* webpackChunkName: "components/atom-icon-app-cat-jumping-left" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCatParading = () => import('../../components/Atom/IconApp/IconAppCatParading.vue' /* webpackChunkName: "components/atom-icon-app-cat-parading" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCatSitting = () => import('../../components/Atom/IconApp/IconAppCatSitting.vue' /* webpackChunkName: "components/atom-icon-app-cat-sitting" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCatWalking = () => import('../../components/Atom/IconApp/IconAppCatWalking.vue' /* webpackChunkName: "components/atom-icon-app-cat-walking" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCatWashing = () => import('../../components/Atom/IconApp/IconAppCatWashing.vue' /* webpackChunkName: "components/atom-icon-app-cat-washing" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCheckboxChecked = () => import('../../components/Atom/IconApp/IconAppCheckboxChecked.vue' /* webpackChunkName: "components/atom-icon-app-checkbox-checked" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppCheckboxUnchecked = () => import('../../components/Atom/IconApp/IconAppCheckboxUnchecked.vue' /* webpackChunkName: "components/atom-icon-app-checkbox-unchecked" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppDog = () => import('../../components/Atom/IconApp/IconAppDog.vue' /* webpackChunkName: "components/atom-icon-app-dog" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppExclamationMark = () => import('../../components/Atom/IconApp/IconAppExclamationMark.vue' /* webpackChunkName: "components/atom-icon-app-exclamation-mark" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppFacebook = () => import('../../components/Atom/IconApp/IconAppFacebook.vue' /* webpackChunkName: "components/atom-icon-app-facebook" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppFacebookF = () => import('../../components/Atom/IconApp/IconAppFacebookF.vue' /* webpackChunkName: "components/atom-icon-app-facebook-f" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppFemale = () => import('../../components/Atom/IconApp/IconAppFemale.vue' /* webpackChunkName: "components/atom-icon-app-female" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppFemaleLarge = () => import('../../components/Atom/IconApp/IconAppFemaleLarge.vue' /* webpackChunkName: "components/atom-icon-app-female-large" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppFilter = () => import('../../components/Atom/IconApp/IconAppFilter.vue' /* webpackChunkName: "components/atom-icon-app-filter" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppGlobe = () => import('../../components/Atom/IconApp/IconAppGlobe.vue' /* webpackChunkName: "components/atom-icon-app-globe" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppHeart = () => import('../../components/Atom/IconApp/IconAppHeart.vue' /* webpackChunkName: "components/atom-icon-app-heart" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppHeartFilled = () => import('../../components/Atom/IconApp/IconAppHeartFilled.vue' /* webpackChunkName: "components/atom-icon-app-heart-filled" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppLinkedIn = () => import('../../components/Atom/IconApp/IconAppLinkedIn.vue' /* webpackChunkName: "components/atom-icon-app-linked-in" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppLoader = () => import('../../components/Atom/IconApp/IconAppLoader.vue' /* webpackChunkName: "components/atom-icon-app-loader" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppLocate = () => import('../../components/Atom/IconApp/IconAppLocate.vue' /* webpackChunkName: "components/atom-icon-app-locate" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppLocationPin = () => import('../../components/Atom/IconApp/IconAppLocationPin.vue' /* webpackChunkName: "components/atom-icon-app-location-pin" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppLogOut = () => import('../../components/Atom/IconApp/IconAppLogOut.vue' /* webpackChunkName: "components/atom-icon-app-log-out" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppMale = () => import('../../components/Atom/IconApp/IconAppMale.vue' /* webpackChunkName: "components/atom-icon-app-male" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppMaleLarge = () => import('../../components/Atom/IconApp/IconAppMaleLarge.vue' /* webpackChunkName: "components/atom-icon-app-male-large" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppParrot = () => import('../../components/Atom/IconApp/IconAppParrot.vue' /* webpackChunkName: "components/atom-icon-app-parrot" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppPencil = () => import('../../components/Atom/IconApp/IconAppPencil.vue' /* webpackChunkName: "components/atom-icon-app-pencil" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppPig = () => import('../../components/Atom/IconApp/IconAppPig.vue' /* webpackChunkName: "components/atom-icon-app-pig" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppRabbit = () => import('../../components/Atom/IconApp/IconAppRabbit.vue' /* webpackChunkName: "components/atom-icon-app-rabbit" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppRat = () => import('../../components/Atom/IconApp/IconAppRat.vue' /* webpackChunkName: "components/atom-icon-app-rat" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppUnknownGender = () => import('../../components/Atom/IconApp/IconAppUnknownGender.vue' /* webpackChunkName: "components/atom-icon-app-unknown-gender" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppXFormerlyKnownAsTwitter = () => import('../../components/Atom/IconApp/IconAppXFormerlyKnownAsTwitter.vue' /* webpackChunkName: "components/atom-icon-app-x-formerly-known-as-twitter" */).then(c => wrapFunctional(c.default || c))
export const AtomIconAppYoutube = () => import('../../components/Atom/IconApp/IconAppYoutube.vue' /* webpackChunkName: "components/atom-icon-app-youtube" */).then(c => wrapFunctional(c.default || c))
export const AtomBrandLidLogo = () => import('../../components/Atom/Brand/Lid/Logo.vue' /* webpackChunkName: "components/atom-brand-lid-logo" */).then(c => wrapFunctional(c.default || c))
export const DebugDump = () => import('../../components/Global/DebugDump.vue' /* webpackChunkName: "components/debug-dump" */).then(c => wrapFunctional(c.default || c))
export const SmoothReflow = () => import('../../components/Global/SmoothReflow.vue' /* webpackChunkName: "components/smooth-reflow" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldAgree = () => import('../../components/Global/Formie/FormieFieldAgree.vue' /* webpackChunkName: "components/formie-field-agree" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldCheckboxes = () => import('../../components/Global/Formie/FormieFieldCheckboxes.vue' /* webpackChunkName: "components/formie-field-checkboxes" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldDate = () => import('../../components/Global/Formie/FormieFieldDate.vue' /* webpackChunkName: "components/formie-field-date" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldDropdown = () => import('../../components/Global/Formie/FormieFieldDropdown.vue' /* webpackChunkName: "components/formie-field-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldEmail = () => import('../../components/Global/Formie/FormieFieldEmail.vue' /* webpackChunkName: "components/formie-field-email" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldEntries = () => import('../../components/Global/Formie/FormieFieldEntries.vue' /* webpackChunkName: "components/formie-field-entries" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldFileUpload = () => import('../../components/Global/Formie/FormieFieldFileUpload.vue' /* webpackChunkName: "components/formie-field-file-upload" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldGroup = () => import('../../components/Global/Formie/FormieFieldGroup.vue' /* webpackChunkName: "components/formie-field-group" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldHeading = () => import('../../components/Global/Formie/FormieFieldHeading.vue' /* webpackChunkName: "components/formie-field-heading" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldHidden = () => import('../../components/Global/Formie/FormieFieldHidden.vue' /* webpackChunkName: "components/formie-field-hidden" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldHtml = () => import('../../components/Global/Formie/FormieFieldHtml.vue' /* webpackChunkName: "components/formie-field-html" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldMultiLineText = () => import('../../components/Global/Formie/FormieFieldMultiLineText.vue' /* webpackChunkName: "components/formie-field-multi-line-text" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldName = () => import('../../components/Global/Formie/FormieFieldName.vue' /* webpackChunkName: "components/formie-field-name" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldNumber = () => import('../../components/Global/Formie/FormieFieldNumber.vue' /* webpackChunkName: "components/formie-field-number" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldPhone = () => import('../../components/Global/Formie/FormieFieldPhone.vue' /* webpackChunkName: "components/formie-field-phone" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldRadio = () => import('../../components/Global/Formie/FormieFieldRadio.vue' /* webpackChunkName: "components/formie-field-radio" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldRepeater = () => import('../../components/Global/Formie/FormieFieldRepeater.vue' /* webpackChunkName: "components/formie-field-repeater" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldSection = () => import('../../components/Global/Formie/FormieFieldSection.vue' /* webpackChunkName: "components/formie-field-section" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldSingleLineText = () => import('../../components/Global/Formie/FormieFieldSingleLineText.vue' /* webpackChunkName: "components/formie-field-single-line-text" */).then(c => wrapFunctional(c.default || c))
export const FormieForm = () => import('../../components/Global/Formie/FormieForm.vue' /* webpackChunkName: "components/formie-form" */).then(c => wrapFunctional(c.default || c))
export const FormieRows = () => import('../../components/Global/Formie/FormieRows.vue' /* webpackChunkName: "components/formie-rows" */).then(c => wrapFunctional(c.default || c))
export const MoleculeAnimalCardV2 = () => import('../../components/Molecule/AnimalCardV2.vue' /* webpackChunkName: "components/molecule-animal-card-v2" */).then(c => wrapFunctional(c.default || c))
export const MoleculeAnimalCardV2Preloader = () => import('../../components/Molecule/AnimalCardV2Preloader.vue' /* webpackChunkName: "components/molecule-animal-card-v2-preloader" */).then(c => wrapFunctional(c.default || c))
export const MoleculeAnimalCategories = () => import('../../components/Molecule/AnimalCategories.vue' /* webpackChunkName: "components/molecule-animal-categories" */).then(c => wrapFunctional(c.default || c))
export const MoleculeAnimalDetailBlocks = () => import('../../components/Molecule/AnimalDetailBlocks.vue' /* webpackChunkName: "components/molecule-animal-detail-blocks" */).then(c => wrapFunctional(c.default || c))
export const MoleculeAnimalMeetYourMatch = () => import('../../components/Molecule/AnimalMeetYourMatch.vue' /* webpackChunkName: "components/molecule-animal-meet-your-match" */).then(c => wrapFunctional(c.default || c))
export const MoleculeBadge = () => import('../../components/Molecule/Badge.vue' /* webpackChunkName: "components/molecule-badge" */).then(c => wrapFunctional(c.default || c))
export const MoleculeBadgeInCorner = () => import('../../components/Molecule/BadgeInCorner.vue' /* webpackChunkName: "components/molecule-badge-in-corner" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCardAnimalFilter = () => import('../../components/Molecule/CardAnimalFilter.vue' /* webpackChunkName: "components/molecule-card-animal-filter" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCardHighlight = () => import('../../components/Molecule/CardHighlight.vue' /* webpackChunkName: "components/molecule-card-highlight" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCardOverview = () => import('../../components/Molecule/CardOverview.vue' /* webpackChunkName: "components/molecule-card-overview" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCardOverviewCover = () => import('../../components/Molecule/CardOverviewCover.vue' /* webpackChunkName: "components/molecule-card-overview-cover" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCardOverviewWide = () => import('../../components/Molecule/CardOverviewWide.vue' /* webpackChunkName: "components/molecule-card-overview-wide" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCardSimple = () => import('../../components/Molecule/CardSimple.vue' /* webpackChunkName: "components/molecule-card-simple" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCardText = () => import('../../components/Molecule/CardText.vue' /* webpackChunkName: "components/molecule-card-text" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCollage = () => import('../../components/Molecule/Collage.vue' /* webpackChunkName: "components/molecule-collage" */).then(c => wrapFunctional(c.default || c))
export const MoleculeDesktopMenuItem = () => import('../../components/Molecule/DesktopMenuItem.vue' /* webpackChunkName: "components/molecule-desktop-menu-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculeDonation = () => import('../../components/Molecule/Donation.vue' /* webpackChunkName: "components/molecule-donation" */).then(c => wrapFunctional(c.default || c))
export const MoleculeFavorite = () => import('../../components/Molecule/Favorite.vue' /* webpackChunkName: "components/molecule-favorite" */).then(c => wrapFunctional(c.default || c))
export const MoleculeFilter = () => import('../../components/Molecule/Filter.vue' /* webpackChunkName: "components/molecule-filter" */).then(c => wrapFunctional(c.default || c))
export const MoleculeFooterSocialMedia = () => import('../../components/Molecule/FooterSocialMedia.vue' /* webpackChunkName: "components/molecule-footer-social-media" */).then(c => wrapFunctional(c.default || c))
export const MoleculeHeaderAnimalHome = () => import('../../components/Molecule/HeaderAnimalHome.vue' /* webpackChunkName: "components/molecule-header-animal-home" */).then(c => wrapFunctional(c.default || c))
export const MoleculeHeaderHome = () => import('../../components/Molecule/HeaderHome.vue' /* webpackChunkName: "components/molecule-header-home" */).then(c => wrapFunctional(c.default || c))
export const MoleculeHeaderOverview = () => import('../../components/Molecule/HeaderOverview.vue' /* webpackChunkName: "components/molecule-header-overview" */).then(c => wrapFunctional(c.default || c))
export const MoleculeHighlightBanner = () => import('../../components/Molecule/HighlightBanner.vue' /* webpackChunkName: "components/molecule-highlight-banner" */).then(c => wrapFunctional(c.default || c))
export const MoleculeHighlightedAnimals = () => import('../../components/Molecule/HighlightedAnimals.vue' /* webpackChunkName: "components/molecule-highlighted-animals" */).then(c => wrapFunctional(c.default || c))
export const MoleculeHighlightedSliderWithText = () => import('../../components/Molecule/HighlightedSliderWithText.vue' /* webpackChunkName: "components/molecule-highlighted-slider-with-text" */).then(c => wrapFunctional(c.default || c))
export const MoleculeImageCarousel = () => import('../../components/Molecule/ImageCarousel.vue' /* webpackChunkName: "components/molecule-image-carousel" */).then(c => wrapFunctional(c.default || c))
export const MoleculeInputSearch = () => import('../../components/Molecule/InputSearch.vue' /* webpackChunkName: "components/molecule-input-search" */).then(c => wrapFunctional(c.default || c))
export const MoleculeInputValue = () => import('../../components/Molecule/InputValue.vue' /* webpackChunkName: "components/molecule-input-value" */).then(c => wrapFunctional(c.default || c))
export const MoleculeLoaderOverlay = () => import('../../components/Molecule/LoaderOverlay.vue' /* webpackChunkName: "components/molecule-loader-overlay" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMission = () => import('../../components/Molecule/Mission.vue' /* webpackChunkName: "components/molecule-mission" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMobileMenuItem = () => import('../../components/Molecule/MobileMenuItem.vue' /* webpackChunkName: "components/molecule-mobile-menu-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculePageDetailReferences = () => import('../../components/Molecule/PageDetailReferences.vue' /* webpackChunkName: "components/molecule-page-detail-references" */).then(c => wrapFunctional(c.default || c))
export const MoleculePicture = () => import('../../components/Molecule/Picture.vue' /* webpackChunkName: "components/molecule-picture" */).then(c => wrapFunctional(c.default || c))
export const MoleculeQuestionAndAnswer = () => import('../../components/Molecule/QuestionAndAnswer.vue' /* webpackChunkName: "components/molecule-question-and-answer" */).then(c => wrapFunctional(c.default || c))
export const MoleculeResultsAndOrderBy = () => import('../../components/Molecule/ResultsAndOrderBy.vue' /* webpackChunkName: "components/molecule-results-and-order-by" */).then(c => wrapFunctional(c.default || c))
export const MoleculeShare = () => import('../../components/Molecule/Share.vue' /* webpackChunkName: "components/molecule-share" */).then(c => wrapFunctional(c.default || c))
export const MoleculeTabbedAccountMenu = () => import('../../components/Molecule/TabbedAccountMenu.vue' /* webpackChunkName: "components/molecule-tabbed-account-menu" */).then(c => wrapFunctional(c.default || c))
export const MoleculeTableRowKeyValue = () => import('../../components/Molecule/TableRowKeyValue.vue' /* webpackChunkName: "components/molecule-table-row-key-value" */).then(c => wrapFunctional(c.default || c))
export const MoleculeWriter = () => import('../../components/Molecule/Writer.vue' /* webpackChunkName: "components/molecule-writer" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCarousel = () => import('../../components/Molecule/Carousel/Carousel.vue' /* webpackChunkName: "components/molecule-carousel" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCarouselSlideAnimal = () => import('../../components/Molecule/Carousel/CarouselSlideAnimal.vue' /* webpackChunkName: "components/molecule-carousel-slide-animal" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCarouselSlideStory = () => import('../../components/Molecule/Carousel/CarouselSlideStory.vue' /* webpackChunkName: "components/molecule-carousel-slide-story" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCarouselLidCarousel = () => import('../../components/Molecule/Carousel/LidCarousel.vue' /* webpackChunkName: "components/molecule-carousel-lid-carousel" */).then(c => wrapFunctional(c.default || c))
export const MoleculeCarouselLidCarouselSlideStory = () => import('../../components/Molecule/Carousel/LidCarouselSlideStory.vue' /* webpackChunkName: "components/molecule-carousel-lid-carousel-slide-story" */).then(c => wrapFunctional(c.default || c))
export const MoleculeLidFooterSocialMedia = () => import('../../components/Molecule/Lid/FooterSocialMedia.vue' /* webpackChunkName: "components/molecule-lid-footer-social-media" */).then(c => wrapFunctional(c.default || c))
export const MoleculeLidHeader = () => import('../../components/Molecule/Lid/Header.vue' /* webpackChunkName: "components/molecule-lid-header" */).then(c => wrapFunctional(c.default || c))
export const MoleculeLidHeaderOverview = () => import('../../components/Molecule/Lid/HeaderOverview.vue' /* webpackChunkName: "components/molecule-lid-header-overview" */).then(c => wrapFunctional(c.default || c))
export const MoleculeLidHighlightArticle = () => import('../../components/Molecule/Lid/HighlightArticle.vue' /* webpackChunkName: "components/molecule-lid-highlight-article" */).then(c => wrapFunctional(c.default || c))
export const MoleculeLidHighlightBanner = () => import('../../components/Molecule/Lid/HighlightBanner.vue' /* webpackChunkName: "components/molecule-lid-highlight-banner" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixBanner = () => import('../../components/Molecule/Matrix/MatrixBanner.vue' /* webpackChunkName: "components/molecule-matrix-banner" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixDonationIframe = () => import('../../components/Molecule/Matrix/MatrixDonationIframe.vue' /* webpackChunkName: "components/molecule-matrix-donation-iframe" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixExternalForm = () => import('../../components/Molecule/Matrix/MatrixExternalForm.vue' /* webpackChunkName: "components/molecule-matrix-external-form" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixForm = () => import('../../components/Molecule/Matrix/MatrixForm.vue' /* webpackChunkName: "components/molecule-matrix-form" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixImage = () => import('../../components/Molecule/Matrix/MatrixImage.vue' /* webpackChunkName: "components/molecule-matrix-image" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixImageCarousel = () => import('../../components/Molecule/Matrix/MatrixImageCarousel.vue' /* webpackChunkName: "components/molecule-matrix-image-carousel" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixKentaaIframe = () => import('../../components/Molecule/Matrix/MatrixKentaaIframe.vue' /* webpackChunkName: "components/molecule-matrix-kentaa-iframe" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixQuestionAnswers = () => import('../../components/Molecule/Matrix/MatrixQuestionAnswers.vue' /* webpackChunkName: "components/molecule-matrix-question-answers" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixQuote = () => import('../../components/Molecule/Matrix/MatrixQuote.vue' /* webpackChunkName: "components/molecule-matrix-quote" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixRawText = () => import('../../components/Molecule/Matrix/MatrixRawText.vue' /* webpackChunkName: "components/molecule-matrix-raw-text" */).then(c => wrapFunctional(c.default || c))
export const MoleculeMatrixText = () => import('../../components/Molecule/Matrix/MatrixText.vue' /* webpackChunkName: "components/molecule-matrix-text" */).then(c => wrapFunctional(c.default || c))
export const MoleculeUserActivateAccount = () => import('../../components/Molecule/User/UserActivateAccount.vue' /* webpackChunkName: "components/molecule-user-activate-account" */).then(c => wrapFunctional(c.default || c))
export const MoleculeUserDetailsForm = () => import('../../components/Molecule/User/UserDetailsForm.vue' /* webpackChunkName: "components/molecule-user-details-form" */).then(c => wrapFunctional(c.default || c))
export const MoleculeUserLoginForm = () => import('../../components/Molecule/User/UserLoginForm.vue' /* webpackChunkName: "components/molecule-user-login-form" */).then(c => wrapFunctional(c.default || c))
export const MoleculeUserPasswordResetForm = () => import('../../components/Molecule/User/UserPasswordResetForm.vue' /* webpackChunkName: "components/molecule-user-password-reset-form" */).then(c => wrapFunctional(c.default || c))
export const MoleculeUserPasswordSetForm = () => import('../../components/Molecule/User/UserPasswordSetForm.vue' /* webpackChunkName: "components/molecule-user-password-set-form" */).then(c => wrapFunctional(c.default || c))
export const MoleculeUserRegisterForm = () => import('../../components/Molecule/User/UserRegisterForm.vue' /* webpackChunkName: "components/molecule-user-register-form" */).then(c => wrapFunctional(c.default || c))
export const MoleculeUserResendActivationEmail = () => import('../../components/Molecule/User/UserResendActivationEmail.vue' /* webpackChunkName: "components/molecule-user-resend-activation-email" */).then(c => wrapFunctional(c.default || c))
export const OrganismControlPanel = () => import('../../components/Organism/ControlPanel.vue' /* webpackChunkName: "components/organism-control-panel" */).then(c => wrapFunctional(c.default || c))
export const OrganismDesktopMenu = () => import('../../components/Organism/DesktopMenu.vue' /* webpackChunkName: "components/organism-desktop-menu" */).then(c => wrapFunctional(c.default || c))
export const OrganismFooter = () => import('../../components/Organism/Footer.vue' /* webpackChunkName: "components/organism-footer" */).then(c => wrapFunctional(c.default || c))
export const OrganismLocationSearchInput = () => import('../../components/Organism/LocationSearchInput.vue' /* webpackChunkName: "components/organism-location-search-input" */).then(c => wrapFunctional(c.default || c))
export const OrganismMatrix = () => import('../../components/Organism/Matrix.vue' /* webpackChunkName: "components/organism-matrix" */).then(c => wrapFunctional(c.default || c))
export const OrganismMobileMenu = () => import('../../components/Organism/MobileMenu.vue' /* webpackChunkName: "components/organism-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const OrganismNavigationBreadcrumb = () => import('../../components/Organism/NavigationBreadcrumb.vue' /* webpackChunkName: "components/organism-navigation-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const OrganismPagination = () => import('../../components/Organism/Pagination.vue' /* webpackChunkName: "components/organism-pagination" */).then(c => wrapFunctional(c.default || c))
export const OrganismLoader = () => import('../../components/Organism/Loader/Loader.vue' /* webpackChunkName: "components/organism-loader" */).then(c => wrapFunctional(c.default || c))
export const OrganismLoaderFromAnimalId = () => import('../../components/Organism/Loader/LoaderFromAnimalId.vue' /* webpackChunkName: "components/organism-loader-from-animal-id" */).then(c => wrapFunctional(c.default || c))
export const OrganismLoaderFromUri = () => import('../../components/Organism/Loader/LoaderFromUri.vue' /* webpackChunkName: "components/organism-loader-from-uri" */).then(c => wrapFunctional(c.default || c))
export const OrganismLidFooter = () => import('../../components/Organism/Lid/Footer.vue' /* webpackChunkName: "components/organism-lid-footer" */).then(c => wrapFunctional(c.default || c))
export const OrganismLidMenu = () => import('../../components/Organism/Lid/Menu.vue' /* webpackChunkName: "components/organism-lid-menu" */).then(c => wrapFunctional(c.default || c))
export const OrganismLidNavigationBreadcrumb = () => import('../../components/Organism/Lid/NavigationBreadcrumb.vue' /* webpackChunkName: "components/organism-lid-navigation-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const TemplateArticleDetail = () => import('../../components/Template/ArticleDetail.vue' /* webpackChunkName: "components/template-article-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateArticleOverview = () => import('../../components/Template/ArticleOverview.vue' /* webpackChunkName: "components/template-article-overview" */).then(c => wrapFunctional(c.default || c))
export const TemplateBase = () => import('../../components/Template/Base.vue' /* webpackChunkName: "components/template-base" */).then(c => wrapFunctional(c.default || c))
export const TemplateCollectionPage = () => import('../../components/Template/CollectionPage.vue' /* webpackChunkName: "components/template-collection-page" */).then(c => wrapFunctional(c.default || c))
export const TemplateDepartmentOverview = () => import('../../components/Template/DepartmentOverview.vue' /* webpackChunkName: "components/template-department-overview" */).then(c => wrapFunctional(c.default || c))
export const TemplateError = () => import('../../components/Template/Error.vue' /* webpackChunkName: "components/template-error" */).then(c => wrapFunctional(c.default || c))
export const TemplateHomeDetail = () => import('../../components/Template/HomeDetail.vue' /* webpackChunkName: "components/template-home-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateJobOfferDetail = () => import('../../components/Template/JobOfferDetail.vue' /* webpackChunkName: "components/template-job-offer-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateJobOfferOverview = () => import('../../components/Template/JobOfferOverview.vue' /* webpackChunkName: "components/template-job-offer-overview" */).then(c => wrapFunctional(c.default || c))
export const TemplateLandingDetail = () => import('../../components/Template/LandingDetail.vue' /* webpackChunkName: "components/template-landing-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateOverview = () => import('../../components/Template/Overview.vue' /* webpackChunkName: "components/template-overview" */).then(c => wrapFunctional(c.default || c))
export const TemplatePageDetail = () => import('../../components/Template/PageDetail.vue' /* webpackChunkName: "components/template-page-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplatePageOverview = () => import('../../components/Template/PageOverview.vue' /* webpackChunkName: "components/template-page-overview" */).then(c => wrapFunctional(c.default || c))
export const TemplateShelterDetail = () => import('../../components/Template/ShelterDetail.vue' /* webpackChunkName: "components/template-shelter-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateSpecial = () => import('../../components/Template/Special.vue' /* webpackChunkName: "components/template-special" */).then(c => wrapFunctional(c.default || c))
export const TemplateVariantHomeDetail = () => import('../../components/Template/VariantHomeDetail.vue' /* webpackChunkName: "components/template-variant-home-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateAnimalDetail = () => import('../../components/Template/Animal/AnimalDetail.vue' /* webpackChunkName: "components/template-animal-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateAnimalOverview = () => import('../../components/Template/Animal/AnimalOverview.vue' /* webpackChunkName: "components/template-animal-overview" */).then(c => wrapFunctional(c.default || c))
export const TemplateAnimalSuccessDetail = () => import('../../components/Template/Animal/AnimalSuccessDetail.vue' /* webpackChunkName: "components/template-animal-success-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateAnimalSuccessOverview = () => import('../../components/Template/Animal/AnimalSuccessOverview.vue' /* webpackChunkName: "components/template-animal-success-overview" */).then(c => wrapFunctional(c.default || c))
export const TemplateLidArticleDetail = () => import('../../components/Template/Lid/LidArticleDetail.vue' /* webpackChunkName: "components/template-lid-article-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateLidArticleOverview = () => import('../../components/Template/Lid/LidArticleOverview.vue' /* webpackChunkName: "components/template-lid-article-overview" */).then(c => wrapFunctional(c.default || c))
export const TemplateLidBase = () => import('../../components/Template/Lid/LidBase.vue' /* webpackChunkName: "components/template-lid-base" */).then(c => wrapFunctional(c.default || c))
export const TemplateLidError = () => import('../../components/Template/Lid/LidError.vue' /* webpackChunkName: "components/template-lid-error" */).then(c => wrapFunctional(c.default || c))
export const TemplateLidLandingDetail = () => import('../../components/Template/Lid/LidLandingDetail.vue' /* webpackChunkName: "components/template-lid-landing-detail" */).then(c => wrapFunctional(c.default || c))
export const TemplateLidOverview = () => import('../../components/Template/Lid/LidOverview.vue' /* webpackChunkName: "components/template-lid-overview" */).then(c => wrapFunctional(c.default || c))
export const TemplateLidPageDetail = () => import('../../components/Template/Lid/LidPageDetail.vue' /* webpackChunkName: "components/template-lid-page-detail" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
